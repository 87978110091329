import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import  { getItem }  from '../../utils';
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
  
  const { t } = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(false);
  const { login } = useAuth();

  useEffect(() => {
    // If there are errors, set a timeout to clear them after 5 seconds
    if (errors) {
      const timer = setTimeout(() => {
        setErrors({});
      }, 5000);

      // Clear the timeout if the component is unmounted or if the user clicks an input field
      return () => clearTimeout(timer);
    }
  }, [errors]);

  const handleLogin = async (e) => {

    e.preventDefault();
    // Here you would usually send a request to your backend to authenticate the user
    // For the sake of this example, we're using a mock authentication
    
    var loginInfo = {
      username,
      password,
      seicrit: process.env.REACT_APP_HEMLI_KODE,
      action: 'login',
    }

    try {
      const userInfo = await getItem(loginInfo);
      
      if(userInfo['isLogedIn']) {
          await login({ userInfo });
      } else {
          //console.error("Invalid username or password");          
          setErrors(true)
      }
    } catch (error) {
        console.error("Error during login:", error);
    }
  };
  return (
    <div>
      <form onSubmit={handleLogin}>
      
  
          <label htmlFor="username">{t('email-or-username')}:</label>
          <input
            id="e-mail"
            name="e-mail"
            type="text"            
            placeholder={t('write-email-or-username')}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <label htmlFor="password">{t('password')}:</label>
          <input
            id="password"
            name="password"
            type="password"
            placeholder={t('write-password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p>{errors && "Invalid username or password"}</p>
    
        <button className="button" id="button-login" style={{margin: "19px 0 38px"}}>{t('login')}</button>
                                
      </form>
    </div>
  );
};

export default LoginForm;