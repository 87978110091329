import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RegisterForm = ({ props, setErrors }) => { 

  const { t } = useTranslation();

  useEffect(() => {
    // If there are errors, set a timeout to clear them after 5 seconds
    if (props.errors) {
      const timer = setTimeout(() => {
        setErrors({});
      }, 5000);

      // Clear the timeout if the component is unmounted or if the user clicks an input field
      return () => clearTimeout(timer);
    }
  }, [props.errors]);

  const handleFocus = () => {
    // Clear the errors when an input is focused
    setErrors({});
  };

  if (props.submitted) {
    return (
    <div className='flex flex-align-center-h flex-align-center-v'>            
      <form className='wrapper-desktop card card-large' onSubmit={props.handleUserConfirmation}>
        <p>{t('code-sent')} {props.formData.email}, {t('write-code')}</p>
        <input 
          type="text"
          name="code"
          placeholder={t('write-code-here')}
          value={props.formData.code}
          onChange={props.handleChange}>
        </input>                      
        <p>{t('check-junkmail')}</p>
        <br></br>
        <button className='button' type="submit">{t('confirm')}</button>
      </form>
      </div>
    );
  }

  return (
    <div className='flex flex-align-center-h flex-align-center-v'>             
        <form className='wrapper-desktop card card-large register-form' onSubmit={props.handleSubmit}>
          <h1 style={{marginBottom: '22px'}}>{t('add-info')}</h1> 
          <div>
            <label>
              {t('first-name')}
              <input
                type="text"
                name="firstname"
                value={props.formData.firstname}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.firstname && <p>{props.errors.firstname}</p>}
          </div>
          <div>
            <label>
              {t('last-name')}
              <input
                type="text"
                name="lastname"
                value={props.formData.lastname}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.lastname && <p>{props.errors.lastname}</p>}
          </div>
          <div>
            <label>
              {t('email')}
              <input
                type="email"
                name="email"
                value={props.formData.email}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.email && <p>{props.errors.email}</p>}
          </div>
          <div>
            <label>
              {t('phone-nr')}
              <input
                type="text"
                name="tel"
                value={props.formData.tel}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.tel && <p>{props.errors.tel}</p>}
          </div>
          <div>
            <label>
              {t('address')}
              <input
                type="text"
                name="adress"
                value={props.formData.adress}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.adress && <p>{props.errors.adress}</p>}
          </div>
          <div>
            <label>
              {t('postal-number')}
              <input
                type="numer"
                name="postnummer"
                value={props.formData.postnummer }
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.postnummer  && <p>{props.errors.postnummer }</p>}
          </div>
          <div>
            <label>
              {t('i-am')}
            </label>
            <div className="radio-button">              
              <input 
              type="radio"
              id="adult"
              name="member_age_group"
              value="1"
              checked={props.formData.member_age_group === "1"}
              onChange={props.handleChange}
              />
              <label htmlFor="html">{t('adult')}</label>
              <br />              
              <input 
                type="radio"
                id="other"
                name="member_age_group"
                value="2"
                checked={props.formData.member_age_group === "2"}
                onChange={props.handleChange}
              />
              <label htmlFor="css">{t('other')}</label>
            </div>
          </div>
          <div>
            <label>
              {t('password')}
              <input
                type="password"
                name="password"
                value={props.formData.password}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.password && <p>{props.errors.password}</p>}
          </div>
          <div>
            <label>
              {t('confirm-password')}
              <input
                type="password"
                name="confirmPassword"
                value={props.formData.confirmPassword}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.confirmPassword && <p>{props.errors.confirmPassword}</p>}
          </div>
          <button className='button' type="submit">{t('register-button')}</button>
        </form>
      </div>    
  );
};

export default RegisterForm;
