const HoverInfoBox = ({props}) => {
    console.log(props)
    return (
        <div className="info-box">
        <strong>Namn:</strong> {props.firstname} {props.lastname} <br />
        <strong>Tel:</strong> {props.tel}<br />
        <strong>Email:</strong> {props.email}<br />
        <strong>Inbjudana</strong><br />
        <strong>Vuxna:</strong> {props.invited_adult}<br />
        <strong>Studerande:</strong>  {props.invited_other}<br />
        <strong>Meddelande:</strong>  {props.note}
        </div>
    );
  };
  
  export default HoverInfoBox;