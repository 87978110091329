import { Routes, Route, Link } from "react-router-dom";
import LoginPage from "./views/LoginPage";
import BookingForm from "./views/BookingForm";
import ActiveBookings from "./views/ActiveBookings";
import Profile from './views/Profile';
import ListAllUser from "./views/ListAllUsers";
import ListAllPrices from "./views/ListAllPrices";
import Gdpr from './views/Gdpr';
import SubmitForm from './views/SubmitForm';
import Terms from './views/Terms';
import RegisterForm from './components/login/RegisterForm'
import PasswordReset from './components/login/PasswordReset';


import "./app.css"

import { ProtectedRoute } from "./services/ProtectedRoute";
import { AdminRoute } from "./services/AdminRoute";
import { AuthProvider } from "./hooks/useAuth"; 

export default function App() {

  return (
    <div className='main' >
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LoginPage />}>
            <Route path="*" element={<NoMatch />} />
          </Route>

          <Route path="/password-reset" element={
            <SubmitForm Form={PasswordReset} />
          } />
          <Route path="/register" element={
            <SubmitForm Form={RegisterForm} />
          } />
          <Route path="/terms" element={<Terms />} />
          <Route path="gdpr" element={<Gdpr />} />

          <Route path="/booking-form" element={
            <ProtectedRoute>
              <BookingForm type={'mobile'} />
            </ProtectedRoute>
          } /> 

          <Route path="/active-bookings" element={
            <ProtectedRoute>
              <ActiveBookings />
            </ProtectedRoute>
          } /> 

          <Route path="/manage-bookings" element={
            <AdminRoute>
              <BookingForm type={'manage'} />
            </AdminRoute>
          } />
          
          <Route path="/manage-users" element={
            <AdminRoute>
              <ListAllUser />
            </AdminRoute>
          } />

          <Route path="/manage-prices" element={
            <AdminRoute>
              <ListAllPrices />
            </AdminRoute>
          } />

          <Route path="/profile/:page?" element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          } /> 
            
          <Route path="/login" element={<LoginPage />} />
        
        </Routes>
      </AuthProvider>


    </div>
    
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
