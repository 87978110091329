import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import sprite from '../../images/icons.svg';

const SelectableCard = ({ availableItems, bookedItems, cardType, handleCheckboxCheck, slide, buttonStyle, selectedDate }) => {
    
    // user var undefined av någon orsak så userLevel hämtas direkt från local storage här
    // Behövs för att medlemmar skall se bord 1 & 8 men inte icke-medlemmar
    const userString = localStorage.getItem('user');
    const userObject = JSON.parse(userString);
    // 1 för icke-medlemmar och 2 för medlemmar
    const userLevel = userObject.userInfo?.userLevel === '306409' || userObject.userInfo?.userLevel === '306410' ? 1:2;

    // Tid före vilket en bokning måste göras
    // 15 för icke medlemmer, 1, och 5 för medlemmar, 2.
    const cutOfTime = userLevel === 1 ? 15:5;

    const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, text: '' });

    const { t } = useTranslation();

    const handleInputClick = (e, isFull, isDisabled) => {
        
        // Kollar ifall tiden fins i bookeItems eller om det fins en diabled, hidden, input 
        // då max två tider är valda
        if ( Array.from(isFull).some(input => input.disabled) || isDisabled ) {
            

            let inputText = ''
            if(isFull) {
                inputText = t('max-table-popup')
            }
            if(isDisabled) {
                inputText = t('no-free-tables')
            }

            const rect = e.target.getBoundingClientRect();
            setTooltip({
                visible: true,
                x: rect.x + window.scrollX + rect.width / 2,
                y: rect.y + window.scrollY,
                text: inputText,
            });
            setTimeout(() => {
                setTooltip({ visible: false, x: 0, y: 0, text: '' });
            }, 2000)
        } else {
            setTooltip({ visible: false, x: 0, y: 0, text: '' });
        }
        
    };

    const itemsId = availableItems.map(item => item.id);
    const itemsName = availableItems.map(item => item.type_of_object);
    //const priceGroup = availableItems.map(item => item.type_of_object);
    
    
    const currentTime = new Date().toTimeString().slice(0, 5);
    const currentDate = new Date();

    const isTimeBeforeCurrent = (time,cutOfTime) => {        
        // 00:00–01:00 ses som en tid på morgon och inte nästa dag
        // den är ju däremot alltid bokningsbar oberoende, väl?
        if(time === '00:00–01:00' || new Date(selectedDate) >= currentDate) {            
            return true;
        }

        // Split the provided time range (e.g., '15:00–16:00') to get start time
        const [startTime] = time.split('–');
        
        // Create a new Date object with the selected date and the start time
        const [hours, minutes] = startTime.split(':');
        const timeDate = new Date(selectedDate);
        timeDate.setHours(hours, minutes, 0, 0); // Set hours and minutes from the time

        // Subtract 5 or 15 minutes from the start time.                
        const timeBefore15Minutes = new Date(timeDate.getTime() - cutOfTime * 60 * 1000).toTimeString().slice(0, 5);

        return currentTime <= timeBefore15Minutes;
    };
 
    const { i18n } = useTranslation();
    const url =
    i18n.language === 'fi'
      ? 'https://www.theclub.fi/jasensaannot'
      : 'https://www.theclub.fi/medlemsregler';

    return (
        <>      
     
        
        {itemsId.length === 0 ? (
            <p>
                <Trans 
                    i18nKey="bookings-closed"
                    components={{ a: <a aria-label='Homepage' href={url} target="_blank" rel="noopener noreferrer" /> }}
                />
            </p>
        ) : (    
            itemsId.map((value, index) => (
                <div 
                    key={index}                 
                    className={`card card-smal card-time flex flex-align-center-h 
                        ${bookedItems.includes(value) || !isTimeBeforeCurrent(itemsName[index],cutOfTime) ? 'unselectable ':'selectable '} 
                        
                        ${
                            // Bord 9-11 och Darts 5 skal inte gå att boka före alla andra är bokade
                            // Hörn borden 1 och 8 skall inte heller visas om inte 2-7 är bokade                            
                            itemsName[index] === 'Pool 9' ||
                            itemsName[index] === 'Pool 10' ||
                            itemsName[index] === 'Pool 11' ||
                            itemsName[index] === 'Darts 5'
                            ? 'hidden':''
                        } 
                        ${
                            // Hörn borden 1 och 8 får en egen klass att trigga remove hidden
                            // gäller inte för användartyp 306409 och 306410
                            (itemsName[index] === 'Pool 1' && userLevel === 1) ||
                            (itemsName[index] === 'Pool 8' && userLevel === 1)
                            ? 'corner':''
                        } 
                        ${itemsName[index].substring(0,4) === 'Pool' ? 'pool':'darts'}`
                    }
                >
                    <label className="flex flex-align-center-h" onClick={(e) => handleInputClick(e, e.currentTarget.children, bookedItems.includes(value))}>
                        <input
                            type="checkbox"
                            disabled={bookedItems.includes(value) || !isTimeBeforeCurrent(itemsName[index],cutOfTime)}
                            data-id={`${cardType}-${value}`}
                            className={`card-smal_checbox checkbox-${cardType}`}
                            onChange={(e) => handleCheckboxCheck(value, e.target.checked,cardType)}                                                                                    
                        />
                        <span className="card-smal_circle"></span>
                        <div className="card-smal_background"></div>
                        <span className="card-smal_text">{itemsName[index]}</span>
                    </label>
                </div>
            ))
        )}

        {tooltip.visible && (
            <div
                className="tooltip"
                style={{ position: 'absolute', top: `${tooltip.y}px`, left: `${tooltip.x}px`, transform: 'translate(-50%, -100%)', backgroundColor: '#333', color: '#fff', padding: '9px', borderRadius: '8px', border: '1px solid var(--main-color)' }}
            >
                {tooltip.text}
            </div>
        )}
       

        <button className="button button-next" style={buttonStyle}  id="next" onClick={ () => slide('next') } >                    
            {t('next')}
            <svg width="7" height="11" >
                <use href={sprite + "#svg-chevron-right"} />
            </svg>
        </button>
        </>
    );
}

export default SelectableCard;