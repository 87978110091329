import { useTranslation } from 'react-i18next';

const PasswordReset = ({props}) => {

  const { t } = useTranslation();

  if (props.resetCode) {
    return (
    <div className='flex flex-align-center-h flex-align-center-v margin-top-20'>            
      <form className='wrapper-desktop card card-large' onSubmit={props.handleSubmitCode}>
        <p>{t('code-sent')} {props.formData.email}, {t('write-code')}</p>
        <input 
          type="text"
          name="code"
          placeholder='_ _ _ _ _'
          value={props.formData.code}
          onChange={props.handleChange}>
        </input>
        {props.errors.code && <p>{props.errors.code}</p>}
        <br></br>
        <p>{t('check-junkmail')}</p>
        <br></br>
        <button className='button' type="submit">{t('confirm')}</button>
      </form>
      </div>
    );
  }

  if (props.resetPassword) {
    return (
    <div className='flex flex-align-center-h flex-align-center-v'>            
      <form  className='wrapper-desktop card card-large' onSubmit={props.handleSubmitPassword}>
        <label>{t('write-new-password')}</label>
        <input 
          id="password"
          type="password"
          name="password"
          placeholder={t('password')}
          value={props.formData.password}
          onChange={props.handleChange}>
        </input>
        <label>{t('repeat-password')}</label>
        <input 
          id="confirmPassword"
          type="password"
          name="confirmPassword"
          placeholder={t('password')}
          value={props.formData.confirmPassword}
          onChange={props.handleChange}>
        </input>
        <br></br>
        <button className='button' type="submit">{t('confirm')}</button>
      </form>
      </div>
    );
  }

  return (
    <div className="margin-top-20">
      <h1 className="text-center">{t('reset-password')}</h1>  
      <div className="card card-large wrapper-desktop center margin-top-10">
          <form onSubmit={props.handleSubmitEmail}>
            <label htmlFor="email">{t('write-email')}</label>
              <input
                id="email"
                type="email"
                name="email"
                placeholder={t('placeholder-email')}
                value={props.formData.email}
                onChange={props.handleChange}
              />
            {props.errors.email && <p>{props.errors.email}</p>}
            <button className="button" type="submit">{t('send')}</button>
          </form>
      </div>
      
    </div>
  );
};

export default PasswordReset;