// DateSelector.js
import sprite from '../images/icons.svg';

const NavigateBack = ({ slide,heading }) => {
  return (
    <span className="screen-nav flex flex-align-center-h wrapper-width navigate-back" style={{marginBottom: "20px"}}>
    <button className="screen-nav-back button-back" title="Back"  onClick={ () => slide('prev') }>
        <svg width="20" height="16" >
            <use href={sprite + "#svg-arrow-back"} />
        </svg> 
    </button>
    <h2>{heading}</h2>
</span>
  );
};

export default NavigateBack;