import { useState ,useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SelectableCards from '../components/booking/SelectableCards';
import ConfirmBooking from '../components/booking/ConfirmBooking';
import DateSelector from '../components/DateSelector';
import NavigateBack from '../components/NavigateBack';
import  { formatTimeName, formatTableName, formatShortDate,  getItem }  from '../utils';

function BookingFormMobile({selectedDate,setSelectedDate,isMobile,user,availableTimes,availableTables}) {   
    document.title = 'The Club - Booking Form';
    
    let maxSelectable = 4;
    
    const [sortedTimes, setSortedTimes] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const bookingContainer = useRef(null)
    // tid å bol checkboxa
    const screenBodyTime = useRef(null)
    const screenbodyTables = useRef(null)

    const { t } = useTranslation();

    function isBeforeThree() {
        const now = new Date();
        const currentHours = now.getHours();
    
        return currentHours < 15;
    }

    const setAvailbableTimes = async () => {
        const response = await getItem({ 
            action: "get-user-times",
            email: user.userInfo.email,
            date: formatShortDate(selectedDate,'unformated')
        });
        
        // Pool och Junior -Fullmember kan boka tider mellan kl. 17-23 om de bokar före kl. 15.00
        if( (user.userInfo.userLevel === '264803' || user.userInfo.userLevel === '507959') && !isBeforeThree ) {
            // om tiden är efter 15.00 så begränsan available times till 0-10 och 17-18.            
            response.splice(1, 0, "10", "17");
        }

        // response[2] fins om man behöver splitta ut två sektioner ur availableTimes
        // ex. 07:00 -> 16:00 och 00:00 -> 01:00
        const times = !response[2]
            ? availableTimes.slice(response[0], response[1])
            : [ ...availableTimes.slice(response[0], response[1]), ...availableTimes.slice(response[2], response[3]) ];

        //console.log(response)
        

        setSortedTimes(times);
    };

    // Samma fins i ActivBookingCard
    useEffect(() => {   
                
        // Kontrolerar så int meir en $nrSelectable kan val väld
        const handleCheckboxChange = (type,nrSelectable) => {   
            
                const checkedCount = bookingContainer.current.querySelectorAll(`.checkbox-${type}:checked`).length;
                bookingContainer.current.querySelectorAll(`.card-smal.selectable .checkbox-${type}:not(:checked)`).forEach(checkbox => {
                    checkbox.disabled = checkedCount >= nrSelectable;
                });
        };
        
        // Attach event listener to the document for event delegation
        screenBodyTime.current.addEventListener('change',  () => handleCheckboxChange('time',maxSelectable));
        screenbodyTables.current.addEventListener('change',  () => handleCheckboxChange('table','1'));

        // Cleanup function to remove the event listener when component unmounts
        //return () => {
        //    screenBodyTime.current.removeEventListener('change',  () => handleCheckboxChange('time','2'));
        //    screenbodyTables.current.removeEventListener('change',  () => handleCheckboxChange('table','1'));
        //};
        
    }, []);

     
    const [selectedItems, setSelectedItems] = useState(
        {
            time: [],
            table: []
        }
    );
   
    const [buttonStyleTime, setButtonStyleTime] = useState({ display: 'none' });
    const [buttonStyleTable, setButtonStyleTable] = useState({ display: 'none' });
    useEffect(() => {           
        if( (selectedItems.time.length !== 0)  ) {
            setButtonStyleTime({
                display: 'initial'
            });
        }
        else {
            setButtonStyleTime({
                display: 'none'
            });
        }
        
        if ( (selectedItems.table.length !== 0) ) {
            setButtonStyleTable({
                display: 'initial'
            });
        }
        else {
            setButtonStyleTable({
                display: 'none'
            });
        }
    }, [selectedItems]);

    
    useEffect(() => {
        if( (selectedItems.table.length !== 0)  ) {

            setSelectedItems(prevState => ({
                ...prevState,
                table: []
            }));

            screenbodyTables.current.querySelectorAll('.card-smal.selectable input[type="checkbox"]').forEach(checkbox => {
                if (checkbox.checked) {
                    checkbox.checked = false;
                }
                checkbox.disabled = false;
            });   
        } 
        
    }, [selectedItems.time]);
    
    useEffect(() => {        
        setSelectedItems( {
            time: [],
            table: []
        })

        
        bookingContainer.current.querySelectorAll('.card-smal.selectable input[type="checkbox"]').forEach(checkbox => {
            if (checkbox.checked) {
                checkbox.checked = false;
            }
            if (checkbox.disabled) {
                checkbox.disabled = false;
            }
        }); 

        setAvailbableTimes();
    }, [selectedDate]);

    function slide(direction) { 
        setCurrentIndex((prevIndex) => {
            if (direction === 'next') {
                // Kollar om man är på första eller andra formulär sidan och att man valt något på dom för att slippa vidare
                if ((prevIndex === 0 && Object.keys(selectedItems.time).length !== 0) || 
                    (prevIndex === 100 && Object.keys(selectedItems.table).length !== 0)) {
                    return prevIndex + 100;
                }
            } else if (direction === 'prev') {
                return prevIndex - 100;
            }   
            
            return prevIndex;
        });
    }

    const handleCheckboxCheck = (value, isChecked,cardType) => {
        if (isChecked) {
            // If checkbox is checked, add the value to selectedItems
            if(cardType === 'time') {
                setSelectedItems(prevState => ({
                    ...prevState,
                    time: [...prevState.time, value]
                }));
            }
            else {
                setSelectedItems(prevState => ({
                    ...prevState,
                    table: [...prevState.table, value]
                }));
            }
        } else {
            // If checkbox is unchecked, remove the value from selectedItems
            if(cardType === 'time') {
                setSelectedItems(prevState => ({
                    ...prevState,
                    time: prevState.time.filter(item => item !== value)
                }));
            }
            else {
                setSelectedItems(prevState => ({
                    ...prevState,
                    table: prevState.table.filter(item => item !== value)
                }));
            }
        }       
    };
        
    const [bookedItems, setBookedItems] = useState([]);
    const [bookedTabels, setBookedTables] = useState([]);
           
    const fetchBookedItems = async (data) => {
        try {        
            const response = await getItem(data);
            
            switch (data.action) {
                case 'get-booked-times':
                    setBookedItems(response);
                    break;    
                case 'get-booked-tables':
                    setBookedTables(response);
                    break;
                default:
                    console.error('Unknown action type:', data.action);
            }

        } catch (error) {
            console.error('Error fetching booked items:', error);
        }        
    };

    useEffect(() => {
        const fetchAllData = async () => {
            await fetchBookedItems({
                date: formatShortDate(selectedDate,'unformated'),
                action: 'get-booked-times'
            });
        };
        fetchAllData();
    }, [selectedDate]);

    useEffect(() => {
        const fetchAllData = async () => {
            await fetchBookedItems({
                date: formatShortDate(selectedDate,'unformated'),
                action: 'get-booked-tables',
                bookedTimes: selectedItems.time
            });
        };
        fetchAllData();
    }, [selectedItems.time]);

    //Kollar om alla  nedrevåningens bord är bokade för vald tid
    //Om alla objekt är bokade så visas övre våningens Bord 9-11 och/eller Darts 5
    useEffect(() => {
        // borden ska inte visas för Darts och Full member
        //if(user.userInfo.userLevel !== '264803' && user.userInfo.userLevel !== '526259') {
            // Räkna antalet bord med klassen type som går att boka, förutom dolda
            const checkFullyBooked = (type) => {
                return screenbodyTables.current.querySelectorAll(`#screen-body-tables .selectable.${type}:not(.hidden)`).length;
            };
        
            // Tar bort klassen som döljer bord som inte skall visas ifall andra inte är fullbokade
            const showHiddenElements = (type,classToRemove) => {
                const hiddenElements = screenbodyTables.current.getElementsByClassName(type);
                Array.from(hiddenElements).forEach(element => element.classList.remove(classToRemove));
            };
        
            // :(
            if (bookedTabels.length !== 0) {
                ['pool', 'darts'].forEach(type => {
                    // Kollar om antal icke bokade borde är 2 eller 1, i så fall är 2-7 fullbokade och 1 och 8 skall visas
                    if ( checkFullyBooked('pool') === 2 || checkFullyBooked('pool') === 1 ) {
                        showHiddenElements('corner','corner');
                    }
                    // Alla bokningsbara icke dolda bord på första våningen är fullbokade så borden 9-11 på över våningen visas
                    if (checkFullyBooked(type) === 0) {
                        showHiddenElements(type,'hidden');
                    }
                });
            }
        //}
        
    }, [bookedTabels]);

  
    return (
        

        
 
            <div id="booking-container" style={{left:`-${currentIndex}vw`}} ref={bookingContainer}>   

                <div style={{position:'fixed',left:'50%',top:'10%',zIndex:'99'}}></div>             
            
                <div className={`screen small-card-screen flex flex-column flex-align-center-h ${currentIndex === 0 ? 'active' : ''}`}>
                    <div className='form-header position-fixed fixed-center width-100vw max-width'>
                        <h2>{t('choose-time')}</h2>
                        <DateSelector 
                            selectedDate={selectedDate} 
                            setSelectedDate={setSelectedDate}
                            type={'mobile'}
                        />    
                    </div>
                    <div id="screen-body-time" ref={screenBodyTime} className="flex flex-column flex-align-center-h flex-1 position-relative width-100 max-width">              
                        {sortedTimes && 
                            <SelectableCards 
                            availableItems={sortedTimes} 
                            bookedItems={bookedItems} 
                            cardType={'time'} 
                            handleCheckboxCheck={handleCheckboxCheck}
                            formatName={formatTimeName}
                            slide={slide} 
                            buttonStyle={buttonStyleTime}
                            selectedDate={selectedDate}
                        />
                        } 
                                     
                    </div>
 
                </div>
            
                <div  className={`screen small-card-screen flex flex-column flex-align-center-h ${currentIndex === 100 ? 'active' : ''}`}>
                    
                    <div className='form-header position-fixed fixed-center width-100vw max-width'>
                        <NavigateBack 
                            slide={slide}
                            heading={t('choose-table')}
                        />
                    </div>

                    <div id="screen-body-tables" ref={screenbodyTables} className="flex flex-column flex-align-center-h width-100 max-width">  
                        {availableTables && selectedItems['time'][0] &&
                            <SelectableCards 
                                availableItems={availableTables} 
                                bookedItems={bookedTabels} 
                                cardType={'table'} 
                                handleCheckboxCheck={handleCheckboxCheck} 
                                formatName={formatTableName}
                                slide={slide} 
                                buttonStyle={buttonStyleTable}
                                selectedDate={selectedDate}
                            />                        
                        }
                    </div>

                </div>
            
                <div className={`screen flex flex-column flex-align-center-h ${currentIndex === 200 ? 'active' : ''}`} style={{overflowY: 'scroll'}}>
                    
                    <div className='width-100vw max-width position-fixed form-header'>
                        <NavigateBack 
                            slide={slide} 
                            heading={t('your-booking')}
                        />
                    </div>
                    
                    {selectedItems['table'][0] && selectedItems['time'][0] && <ConfirmBooking 
                        user={user}                        
                        selectedItems={selectedItems}
                        selectedDate={selectedDate}
                        isMobile={isMobile}
                        availableTables={availableTables}
                    />}  
                </div>                
            </div>        
        
    );
}

export default BookingFormMobile;