import { useState, useEffect } from 'react';
import GridList from '../components/booking/GridList';
import DateSelector from '../components/DateSelector';
import { getItem, formatShortDate } from '../utils';

function BookingFormDesktop({selectedDate,setSelectedDate,user,availableTimes,availableTables}) {
    document.title = 'The Club - Manage bookings';

 //console.log(availableTables);
 
    const [maxSelectable, setMaxItems] = useState();
    const [bookedAllItems, setBookedAllItems] = useState([]);
    const itemsContainer = document.getElementById('items');
 
    useEffect(() => {
        if(itemsContainer){            
            const bookedItems = itemsContainer.getElementsByClassName('unselectable');
            Array.from(bookedItems).forEach(element => {         
                element.classList.remove('unselectable');
            });

            const selectedItems = itemsContainer.getElementsByClassName('selected');
            Array.from(selectedItems).forEach(element => {         
                element.classList.remove('selected');
            });
        } 

        let isMounted = true; // Flag to ensure component is still mounted before updating state

        const fetchAllData = async () => {
            try {        
                const response = await getItem({
                    date: formatShortDate(selectedDate, 'unformated'),
                    action: 'get-all-booked-items'
                });

                // Update state only if the response differs from current state
                if (isMounted && !areArraysEqual(response, bookedAllItems)) {
                    setBookedAllItems(response);
                }
            } catch (error) {
                console.error('Error fetching booked items:', error);
            }
        };

        // Initial fetch
        fetchAllData();

        if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
            // Set up polling interval
            const intervalId = setInterval(fetchAllData, 10000);

            // Cleanup function to clear interval and ensure component is mounted
            return () => {
                clearInterval(intervalId);
                isMounted = false;
            };
        }

    }, [selectedDate]);

    // Helper function to compare arrays for equality
    const areArraysEqual = (arr1, arr2) => {
        arr1.length === arr2.length && arr1.every((item, index) => item === arr2[index]);
    }

    useEffect(() => {
        const getMaxSelectable = async () => {
            try {        
                const response = await getItem({
                  action: 'get-user-level',
                  userLevel: user.userInfo.userLevel
                });        
                setMaxItems(response.maxSelectable);
            } catch (error) {
                console.error('Error fetching active booking:', error);
            }
        };
        getMaxSelectable();
          

    }, []);

    const deactivateBookings = async () => {
        const tempBookingData = updateSelectedItems('set-booking');
        try {        
            await getItem(tempBookingData);
        } catch (error) {
            console.error('Error fetching booked items:', error);
        }
    };
    
    const removeBookings = async () => {
        const tempBookingData = updateSelectedItems('remove-booking');        
        try {        
            await getItem(tempBookingData);
        } catch (error) {
            console.error('Error fetching booked items:', error);
        }
    };
   
    const updateSelectedItems = (action) => {

        let bookingTempData = { 
            action,
            tableId: [],
            date: formatShortDate(selectedDate, 'unformated'),
            timeId: [],
            email: user.userInfo.email,
        }
          
        const selectedElements = itemsContainer.getElementsByClassName('selected');
        const times = [];
        const tables = [];

        Array.from(selectedElements).forEach(element => {

            const time = element.getAttribute('data-id-time');
            const table = element.getAttribute('data-id-table');

            if(action === 'set-booking'){
                element.classList.add('unselectable');
                element.classList.remove('selected');
            }
            if(action === 'remove-booking'){
                element.classList.remove('booked','unselectable','selected');
            }

            times.push(time);
            tables.push(table);

           
        });
    
        bookingTempData.timeId = times;
        bookingTempData.tableId = tables;

        

        return bookingTempData;
      };

  return(   
    <div id="booking-desktop-container">
        
    <div className="small-card-screen flex flex-column center" style={{width:'95%'}}>
        <h2>Välj tidpunkt</h2>

        <DateSelector 
             selectedDate={selectedDate} 
             setSelectedDate={setSelectedDate}
             type={'desktop'}
        />  
        <div id="desktop-grid-items" className="flex">

            <div id="items" className='grid flex-1'>
                
                <div className="list-item list-item-header">Pool 1</div>
                <div className="list-item list-item-header">Pool 2</div>
                <div className="list-item list-item-header">Pool 3</div>
                <div className="list-item list-item-header">Pool 4</div>
                <div className="list-item list-item-header">Pool 5</div>
                <div className="list-item list-item-header">Pool 6</div>
                <div className="list-item list-item-header">Pool 7</div>
                <div className="list-item list-item-header">Pool 8</div>
                <div className="list-item list-item-header">Pool 9</div>
                <div className="list-item list-item-header">Pool 10</div>
                <div className="list-item list-item-header">Pool 11</div>
                <div className="list-item list-item-header">Darts 1</div>
                <div className="list-item list-item-header">Darts 2</div>
                <div className="list-item list-item-header">Darts 3</div>
                <div className="list-item list-item-header">Darts 4</div>
                <div className="list-item list-item-header">Darts 5</div>

                {bookedAllItems && availableTables? (
                    <GridList 
                    maxItems={289}
                    maxSelectable={maxSelectable}
                    bookedAllItems={bookedAllItems}
                    availableTimes={availableTimes}
                    availableTables={availableTables}
                    selectedDate={selectedDate}
                    /> 
                    ) : (
                        <div className="spinner"></div>
                    )
                }
                
                                                  
            </div>     
        </div>
        
        <div style={{marginBottom: '80px',justifyContent: 'center'}} className='flex'>

            <div className=' flex flex-1' style={{alignSelf:'center'}}>

                <div className='flex flex-align-center-h' style={{marginRight: '13px'}}>
                    <div className='bg-red' style={{width: '20px', height: '20px', marginRight: '5px', marginTop: '-1px'}}></div>
                    Admin
                </div>
                <div className='flex flex-align-center-h' style={{marginRight: '13px'}}>
                    <div className='bg-orange' style={{width: '20px', height: '20px', marginRight: '5px', marginTop: '-1px'}}></div>
                    Pool Member
                </div>
                <div className='flex flex-align-center-h' style={{marginRight: '13px'}}>
                    <div className='bg-blue' style={{width: '20px', height: '20px', marginRight: '5px', marginTop: '-1px'}}></div>
                    Darts Member
                </div>
                <div className='flex flex-align-center-h'>
                    <div className='bg-light' style={{width: '20px', height: '20px', marginRight: '5px', marginTop: '-1px'}}></div>
                    Registrerad Icke-medlem
                </div>

            </div>

            <button className='button' style={{width:"200px",marginRight: '20px',backgroundColor: "#66be6e"}} onClick={deactivateBookings}>Boka</button>
            <button className='button' style={{ width:"200px",left:"325px",backgroundColor: "#d02b2b"}} onClick={removeBookings}>Radera bookning</button>
            <div className='flex-1'></div>
        </div>

    </div>
</div>
    
  );
}

export default BookingFormDesktop