import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import  { formatShortDate, getItem, sendEmail }  from '../../utils';
import { availableTimes } from "../../constants";
import sprite from '../../images/icons.svg';

function ConfirmBooking({user, selectedItems, selectedDate,isMobile,availableTables}) {
    //console.log(user);
       
    let navigate = useNavigate();            
    const [bookingExists, setbookingExists] = useState(false);
    const [totalPrice, setTotaltPrice] = useState(0);
    const [participantsNumber, setParticipantsNumber] = useState({        
        adult: 0,
        other: 0        
      });    
    const bookingData = { 
        action: 'set-booking',
        tableId: selectedItems['table'],
        date: formatShortDate(selectedDate, 'unformated'),
        timeId: selectedItems['time'],
        email: user.userInfo.email,
        invited_user: '0',
        invited_adult: participantsNumber.adult,
        invited_other: participantsNumber.other,
        isMobile
    }
    const [availablePrices, setAvailablePrices] = useState([]);
    const selectedIdName = availableTables.find(item => item.id === selectedItems['table'][0])

    const [visitorAdultPrice, setvisitorAdultPrice] = useState();
    const [visitorOtherPrice, setVisitorOtherPrice] = useState();
 
    const nonMemberPrices = {
        306409: {
          Pool : 10,
          Darts: 4
        },
        306410: {
          Pool : 13,
          Darts: 4
        }
      };
      
    

    const { t, i18n } = useTranslation();

    let timeId = availableTimes.find(item => item.id === selectedItems['time'][0]);
    timeId = timeId['type_of_object'];
    if(selectedItems['time'].length === 2) {
        const tempId = availableTimes.find(item => item.id === selectedItems['time'][1]);
        timeId += ' & ' + tempId['type_of_object'];
    }
            

    useEffect(() => {        
        const fetchIdNames = async (action) => {
            const response = await getItem({
                action,
                condition: bookingData.email,
                table:selectedItems['table'][0]
            });            
            setAvailablePrices(response); 
            setvisitorAdultPrice( response['visitorAdult'] );
            setVisitorOtherPrice( response['visitorOther'] );
            setTotaltPrice( calculateTotalPrice(response['user']) ); 
        };        
        
        fetchIdNames('get-all-prices');        
    }, []); 
    
    

    useEffect(() => {        
    // useEffect utan parameter sko vel boda köras jämför me hitt utan föst vel? vel?!
    // He gär e i alla fall int så availablePrices['visitorAdult'] er undefined tils useEffect som körs "föst" körs ti anna.
    if(availablePrices['visitorAdult']) {                        
        //console.log(participantsNumber.adult * availablePrices['visitorAdult']);
            setTotaltPrice( () => {
                if(user.userInfo.userLevel === '306409' || user.userInfo.userLevel === '306410') {
                    return calculateTotalPrice(availablePrices['user']);
                }
                else {
                    return (   
                        ( (parseFloat(participantsNumber.adult * availablePrices['visitorAdult']) +
                        parseFloat(participantsNumber.other * availablePrices['visitorOther']) +
                        parseFloat(availablePrices['user']))  * selectedItems['time'].length ).toFixed(2)
                    )            
                }

            }
        )
    }
    }, [participantsNumber]);

    const calculateTotalPrice = (userPrice) => {
        

        // Vilke typ av användare
        const userLevel = user.userInfo.userLevel;
        // Vilket typ av objekt, Pool 1 - N eller Darts 1 - N
        const objectType = selectedIdName['type_of_object'].startsWith('Pool') ? 'Pool' : 'Darts';
        // Hur många timmar bokat
        const timeLength = selectedItems['time'].length;
        
        // Användare är en Vuxen eller Other registrerad icke-medlem
        if(userLevel === '306409' || userLevel === '306410') {

            let priceAdult = nonMemberPrices[userLevel][objectType];

            if(userLevel === '306409' && participantsNumber.adult > 0 && objectType === 'Pool') {
                priceAdult += 1;
            }

            return (
                (priceAdult * timeLength).toFixed(2)
            );
        }
        // Använaren är en registrerd medlem
        else {
            return (userPrice * timeLength).toFixed(2)
        }
    }    

    const formData = {
        type: 'booking-confirmation'
    }

    const saveItem = async (data) => {
        //console.log(data);
        try {        
            const response = await fetch('https://www.theclub.fi/booking/controler/bookings.php', {
                method: 'POST',
                mode: "cors", // no-cors, *cors, same-origin            
                body: JSON.stringify({
                    ...data
                })
            });
    
            if (!response.ok) {
                throw new Error('Failed to save item');
            } 
            else {
                const jsonData = await response.json();
                if(!jsonData['exists']) {

                    sendEmail({
                        action: 'send-email',
                        user: user.userInfo.firstname,
                        email: user.userInfo.email,
                        time: timeId.replace("–", "-"),
                        date: formatShortDate(selectedDate,null,i18n.language),
                        table: selectedIdName['type_of_object'],
                        lang: i18n.language,
                        formData 
                      })

                    navigate('/active-bookings');                    
                }
                else {
                    setbookingExists(true);
                    setTimeout(() => {
                        setbookingExists(false);
                    }, 10000);                    
                }
            }
        } catch (error) {
            console.error('Error saving item:', error);
        }
    };

    const handleIncrement = (type) => {
        setParticipantsNumber((prevParticipants) => {
        if (prevParticipants.other + prevParticipants.adult >= 3) return prevParticipants;        
            
            const newAdults = type === 6 ? prevParticipants.adult + 1 : prevParticipants.adult;
            const newOthers = type === 7 ? prevParticipants.other + 1 : prevParticipants.other;
        
            return {
                adult: newAdults,
                other: newOthers
            };
        });
    };
    
    const handleDecrement = (type) => {
        setParticipantsNumber((prevParticipants) => {
            if (prevParticipants.other + prevParticipants.adult <= 0) return prevParticipants;      
       
            const newAdults = type === 6 && prevParticipants.adult > 0 ? prevParticipants.adult - 1 : prevParticipants.adult;
            const newOthers = type === 7 && prevParticipants.other > 0 ? prevParticipants.other - 1 : prevParticipants.other;
        
            return {            
                adult: newAdults,
                other: newOthers
            };
        });
    }
    
    return(
    <div id="screen-body-confirm" className="card card-large wrapper-width center flex flex-column width-100 max-width">          
        <strong className='flex-1' style={{marginBottom: "10px"}}>{t('choose-participants')} </strong>

        <p className="font-smal color-gray" style={{marginBottom: "10px"}}>{t('choose-participants-info')}</p>

        <div className='flex flex-align-center-h'>
            <p className="font-smal color-gray">{t('visitor-adult')} </p>
        
            <svg onClick={() => handleDecrement(6)} width="31" height="27" >
                <use href={sprite + "#svg-minus"} />
            </svg>
            
            <span style={{width: "20px",textAlign:"center"}}>{participantsNumber.adult}</span>
            
            <svg onClick={() => handleIncrement(6)} width="31" height="27" >
                <use href={sprite + "#svg-plus"} />
            </svg>

        </div>

        <div className='flex flex-align-center-h' style={{marginBottom: "16px"}}>
            <p className="font-smal color-gray">{t('visitor-other')}</p>
        
            <svg onClick={() => handleDecrement(7)} width="31" height="27" >
                <use href={sprite + "#svg-minus"} />
            </svg>
            
            <span style={{width: "20px",textAlign:"center"}}>{participantsNumber.other}</span>
            
            <svg onClick={() => handleIncrement(7)} width="31" height="27" >
                <use href={sprite + "#svg-plus"} />
            </svg>

        </div>
        
        <span className="gray-line" style={{marginBottom: "12px"}}></span>

        <strong style={{marginBottom: "3px",display: "inline-block"}}>{t('summary')}</strong>
        <p className="font-smal color-gray" style={{marginBottom:" 17px"}}>{t('check-info')}</p>

        <div className="confirm-input-group">
            <span className='label'>{t('name')}</span>
            <span className="confirm-input">{user.userInfo.firstname} {user.userInfo.lastname}</span>
        </div>
        <div className="confirm-input-group">
            <span className='label'>{t('phone-nr')}</span>
            <span className="confirm-input">{user.userInfo.tel}</span>
        </div>
        <div className="confirm-input-group">
            <span className='label'>{t('email')}</span>
            <span className="confirm-input">{user.userInfo.email}</span>
        </div>
        <div className="confirm-input-group">
            <span className='label'>{t('day')} </span>
            <span className="confirm-input" style={{textTransform: 'capitalize'}}>{formatShortDate(selectedDate,null,i18n.language)}</span>
        </div>
        <div className="confirm-input-group">
            <span className='label'>{t('time')}</span>
            <span type="text" className="confirm-input" id="booking-time">
                {timeId}
            </span>
        </div>
        <div className="flex confirm-input-group">
            <label htmlFor="table">{t('table')}</label>
            <span className="confirm-input" id="booking-table">{selectedIdName['type_of_object']}</span>
        </div>
        <div className="confirm-input-group">
            <span className='label' style={{marginBottom: '3px', display: "inline-block"}}>{t('nr-of-participants')}</span><br />
            <span className="confirm-input" id="booking-participant-number" style={{margin: '0px 0px 3px 0px', display:'block'}}>{t('visitor-adult')} &nbsp;
                {participantsNumber.adult} {user.userInfo.userLevel !== '306409' && user.userInfo.userLevel !== '306410' ? `x ${visitorAdultPrice} €`: ""}
            </span>
            <span className="confirm-input" id="booking-participant-number"style={{marginLeft: '0'}}>{t('visitor-other')} &nbsp;
                {participantsNumber.other} {user.userInfo.userLevel !== '306409' && user.userInfo.userLevel !== '306410' ? `x ${visitorOtherPrice} €`: ""}
            </span>
        </div>

        <span className="gray-line"></span>

        <strong style={{marginBottom: "11px", display: "inline-block"}}>{t('total')}&nbsp; 
            {totalPrice}  € 
            ({selectedItems['time'].length} {selectedItems['time'].length <= 1?t('hour'):t('hours')})
        </strong>

        <p className="font-smal color-gray" style={{marginBottom: "8px"}}><Trans i18nKey='possible-discounts'></Trans></p>

        <span className="gray-line"></span>

        <p className="font-smal flex-1" style={{textAlign: "center"}}>{t('use-and-accept')}<br />
            <a className="font-smal" href="/">{t('terms')}</a>
            &nbsp;{t('and')}&nbsp;
            <a className="font-smal" href="/">{t('privacy-policy')}</a>
        </p>

        {bookingExists && (<strong style={{marginTop: '30px'}} className='center bold'>{t('booking-uanvailable')}</strong>)}

        <button className="button" id="button-confirm" onClick={ () => saveItem(bookingData)} style={{marginTop: "19px"}}>
            {t('confirm-booking')}
        </button>
        </div>        
    );
}

export default ConfirmBooking;