import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import  { getItem }  from '../utils';
import ActiveBookingCard from "../components/booking/ActiveBookingCard";
import Footer from "../components/Footer";


const ActiveBookings = () => {
  document.title = 'The Club - Active bookings';

  const { user } = useAuth();
  const [activeBooking, setActiveBooking] = useState(null);
  const [availableTables, setAvailableTables] = useState(null);
  
  useEffect(() => {    
      // Behövs no try catch gär, fins ju å i getItem...
      const fetchTableNames = async () => {
        const response = await getItem({ 
            action: "get-all-objects",
            bookable: true,
        });
        setAvailableTables(response);
    };

    const getActiveBooking = async () => {
      try {        
          const response = await getItem({
            action: 'get-active-bookings',
            email: user.userInfo.email
          });        
          setActiveBooking(response);
      } catch (error) {
          console.error('Error fetching active booking:', error);
      }
    };

    getActiveBooking();
    fetchTableNames();
  }, []);

//console.log(activeBooking[0].date.length)
    return (      
      <>
      <div className="flex flex-column flex-align-center-h active-bookings">
         {activeBooking && availableTables ? (
          <ActiveBookingCard 
            user={user}
            activeBookings={activeBooking}
            availableTables={availableTables}
          />
          ) : (
            <div className="spinner"></div>
          )
        }
      </div>

      <Footer user={user} />
    </>
    );
};
  
export default ActiveBookings;