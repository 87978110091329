// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    fi: {
      translation: {
        "edit-profile": "Muokkaa profiilia",
        "view-costs": "Katso pelimaksut ",
        "active-bookings-link": "Aktiiviset varauksesi",
        "book-pool-and-darts": "Varaa pool & darts",
        "logg-in": "Kirjaudu sisään",
        "email-or-username": "Sähköposti tai käyttäjänimi",
        "write-email-or-username": "Kirjoita sähköpostisi tai käyttäjänimesi",
        "password": "Salasana",
        "write-password": "Kirjoita salasanasi",
        "login": "Kirjaudu sisään",
        "forgotten-password": "Unohtunut salasana?",
        "has-no-account": "Eikö sinulla ole tiliä?",
        "register": "Rekisteröidy",
        "use-and-accept": "Käyttämällä palveluamme hyväksyt",
        "terms": "Ehdot",
        "and": "ja",
        "privacy-policy": "Tietosuojakäytäntö",
        "reset-password": "Nollaa salasanasi",
        "write-email": "Kirjoita sähköpostiosoitteesi",
        "send": "Lähetä",
        "placeholder-email": "nimi@esimerkki.fi",
        "code-sent": "Koodi lähetettiin osoitteeseen",
        "write-code": "Kirjoita koodi alla olevaan kenttään.",
        "check-junkmail": "Jos et saanut koodia, tarkista että sähköpostiosoite on oikein ja että se ei päätynyt roskapostiin.",
        "confirm": "Vahvista",
        "write-new-password": "Kirjoita uusi salasanasi alla olevaan kenttään.",
        "repeat-password": "Kirjoita sama salasana uudelleen.",
        "add-info": "Täytä seuraavat tiedot",
        "first-name": "Etunimi:",
        "last-name": "Sukunimi:",
        "email": "Sähköposti:",
        "phone-nr": "Puhelin:",
        "address": "Osoite: (*vapaaehtoisesti)",
        "postal-number": "Postinumero: (*vapaaehtoisesti)",
        "i-am": "Olen:",
        "adult": "Aikuinen",
        "other": "Opiskelija tai alaikäinen",
        "confirm-password": "Vahvista salasana:",
        "register-button": "Rekisteröidy",
        "choose-time": "Valitse aika",
        "bookings-closed": "Biljardisali on suljettu yleisöltä tänään. Lue lisää jäsenyyksistä <a>verkkosivuillamme.</a>",
        "next": "SEURAAVA",
        "choose-table": "Valitse pöytä",
        "your-booking": "Varaus",
        "max-table-popup": "Voit valita enintään kaksi pöytää. Poista yksi valinta, jos haluat valita toisen.",
        "no-free-tables": "Ei vapaita pöytiä tähän aikaan.",
        "choose-participants": "Valitse osallistujat",
        "choose-participants-info": "Valitse osallistujien määrä varattuun pöytään - enintään 4 henkilöä, mukaan lukien itsesi.",
        "visitor-adult": "Vierailija, aikuinen:",
        "visitor-other": "Vierailija, opiskelija/alaikäinen:",
        "summary": "Yhteenveto",
        "check-info": "Tarkista, että seuraavat tiedot ovat oikein",
        "name": "Nimi:",
        "day": "Päivä:",
        "time": "Aika:",
        "table": "Pöytä:",
        "nr-of-participants": "Osallistujien määrä:",
        "total": "YHTEENSÄ:",
        "possible-discounts": "Mahdolliset opiskelija-alennukset lasketaan kassalla.<br /> Opiskelijakortti on esitettävä.",
        "hour": "tunti",
        "hours": "tuntia",
        "booking-uanvailable": "Valitulle ajalle tai pöydälle on jo tehty varaus. Palaa takaisin ja valitse uusi aika.",
        "confirm-booking": "VAHVISTA VARAUS",
        "confirmation-sent-to": "Vahvistus lähetettiin osoitteeseen",
        "check-junkmail-2": "Tarkista roskapostisi, jos et saanut vahvistusta<br /> joka lähetetään osoitteesta info@theclub.fi",
        "on-table": "PÖYDÄLLÄ",
        "observe": "HUOMIO!<br /> Sinun tulee olla paikalla viimeistään 5 minuuttia ennen<br /> peliajan alkua. Myöhästyessäsi varauksesi voidaan peruuttaa.",
        "cancelation-restriction": "Varauksen voi peruuttaa vain 15 minuuttia ennen peliajan alkua.",
        "cancel-booking": "Peru varaus",
        "no-active-bookings": "Ei aktiivisia varauksia",
        "all-users": "Kaikki käyttäjät",
        "prices": "Hinnat",
        "bookable-objects": "Varattavat kohteet",
        "list-bookings": "Listaa varaukset",
        "member-fees": "Jäsenmaksut",
        "confirm-edit": "Vahvista muokkaus",
        "confirm-delete": "Haluatko varmasti poistaa tämän kentän?",
        "write-code-here": "Syötä koodi tähän",
        "play-time": "SINUN PELIAIKASI",
        "dagar":"päivää",
        "timmar":"tuntia",
        "minuter":"minuuttia",
        "starts-in": "ALKAA"
      }
    },
    sv: {
      translation: {
        "edit-profile": "Editera profil",
        "view-costs": "Visa spelavgifter",
        "active-bookings-link": "Dina aktiva bokningar",
        "book-pool-and-darts": "Boka pool & darts",
        "logg-in": "Logga in",
        "email-or-username": "E-post eller användarnamn",
        "write-email-or-username": "Skriv in din e-post eller användarnamn",
        "password": "Lösenord",
        "write-password": "Skriv in ditt lösenord",
        "login": "Logga in",
        "forgotten-password": "Glömt dit lösenord?",
        "has-no-account": "Har du inget konto?",
        "register": "Registrera dig",
        "use-and-accept": "Genom att använda vår tjänst accepterar du våra",
        "terms": "Vilkor",
        "and": "och",
        "privacy-policy": "Integritestvilkor",
        "reset-password": "Återställ ditt lösenord",
        "write-email": "Skriv in din e-postadress",
        "send": "Skicka",
        "placeholder-email": 'namn@exempel.fi',
        "code-sent": "En kod skickades till",
        "write-code": "skriv in koden i fältet nedan.",
        "check-junkmail": "Ifall du inte fick någon kod dubbelkolla att e-postadressen är korrek och att den inte hamnade i skräpposten.",
        "confirm": "Bekräfta",
        "write-new-password": "Skriv in dit nya nya lösnord in i fältet nedan.",
        "repeat-password": "Skriv in samma lösenord.",
        "add-info": "Fyll i följande uppgifter",
        "first-name": "Förnamn:",
        "last-name": "Efternamn:",
        "email": "E-post:",
        "phone-nr": "Telefon:",
        "address": "Adress: (*frivilligt)",
        "postal-number": "Postnummer: (*frivilligt)",
        "i-am": "Jag är:",
        "adult": "Vuxen",
        "other": "Studerande eller mindreårig",
        "confirm-password": "Bekräfta lösenord:",
        "register-button": "Registrera",
        "choose-time": "Välj tidpunkt",
        "bookings-closed": "Bijardsalen är stängd för allmänheten idag. Läs mer om medlemskap på vår <a>hemsida.</a>",
        "next": "NÄSTA",
        "choose-table": "Välj bord",
        "your-booking": "Din bokning",
        "max-table-popup": "Max två bord kan väljas, avmarkera en för att välja en annan.",
        "no-free-tables": "Inga lediga bord för denna tid.",
        "choose-participants": "Väl antal deltagare",
        "choose-participants-info": "Välj antalet deltagare som kommer att spela vid ditt bokade bord - max 4 inklusive dig själv.",
        "visitor-adult": "Besökare vuxen:",
        "visitor-other": "Besökare studerande/minderårig:",
        "summary": "Sammandrag",
        "check-info": "Kontrollera att följande uppgifter är korrekta",
        "name": "Namn:",
        "day": "Dag:",
        "time": "Tidpunkt:",
        "table": "Bord",
        "nr-of-participants": "Antal deltagare:",
        "total": "TOTALT:",
        "possible-discounts": "Eventuuella studierabatter räknas vid kassan.<br /> Studiekort måste uppvisas",
        "hour": "timme",
        "hours": "timmar",
        "booking-uanvailable": "En bokning med vald tid eller bord har redan gjorts, navigera tillbaka och välj en ny tid.",
        "confirm-booking": "BEKRÄFTA BOKNINGEN",
        "confirmation-sent-to": "En bekräftelse har skickats till",
        "check-junkmail-2": "Kontrollera din skräppost om du inte fick bekräftelsen<br /> som skickas från info@theclub.fi",
        "on-table": "PÅ BORD",
        "observe": "OBSERVERA!<br /> Du bör vara på plats senast 5 minuter före<br /> speltiden börjar. Ifall du blir försenad kan din bokning raderas.",
        "cancelation-restriction": "Bokningar kan endats avbokas fram till 15 minuter före spletiden börjar.",
        "cancel-booking": "Avboka",
        "no-active-bookings": "Inga aktiva bokningar",
        "all-users": "Alla användare",
        "prices": "Priser",
        "bookable-objects": "Bokningsbara objekt",
        "list-bookings": "Lista bokningar",
        "member-fees": "Medlemsavgifter",
        "confirm-edit": "Bekräfta redering",
        "confirm-delete": "Är du säker på att du vill rader detta fält?",
        "write-code-here": "Skriv in koden här",
        "play-time": "DIN SPELTID",
        "dagar":"dagar",
        "timmar":"timmar",
        "minuter":"minuter",
        "starts-in": "BÖRJAR OM"
      }      
    },
    en: {
      translation: {
        "edit-profile": "Edit profile",
        "view-costs": "View game fees",
        "active-bookings-link": "Your active bookings",
        "book-pool-and-darts": "Book pool & darts",
        "logg-in": "Log in",
        "email-or-username": "Email or username",
        "write-email-or-username": "Enter your email or username",
        "password": "Password",
        "write-password": "Enter your password",
        "login": "Log in",
        "forgotten-password": "Forgot your password?",
        "has-no-account": "Don't have an account?",
        "register": "Register",
        "use-and-accept": "By using our service you accept our",
        "terms": "Terms",
        "and": "and",
        "privacy-policy": "Privacy Policy",
        "reset-password": "Reset your password",
        "write-email": "Enter your email address",
        "send": "Send",
        "placeholder-email": "name@example.fi",
        "code-sent": "A code was sent to",
        "write-code": "Enter the code in the field below.",
        "check-junkmail": "If you did not receive a code, double-check that the email address is correct and that it hasn't ended up in the spam folder.",
        "confirm": "Confirm",
        "write-new-password": "Enter your new password in the field below.",
        "repeat-password": "Enter the same password again.",
        "add-info": "Fill in the following information",
        "first-name": "First name:",
        "last-name": "Last name:",
        "email": "Email:",
        "phone-nr": "Phone:",
        "address": "Address: (*optional)",
        "postal-number": "Postal code: (*optional)",
        "i-am": "I am:",
        "adult": "Adult",
        "other": "Student or minor",
        "confirm-password": "Confirm password:",
        "register-button": "Register",
        "choose-time": "Choose time",
        "bookings-closed": "The pool hall is closed to the public today. Read more about memberships on our <a>website.</a>",
        "next": "NEXT",
        "choose-table": "Choose table",
        "your-booking": "Your booking",
        "max-table-popup": "A maximum of two tables can be selected. Deselect one to choose another.",
        "no-free-tables": "No available tables for this time.",
        "choose-participants": "Choose the number of participants",
        "choose-participants-info": "Choose the number of participants playing at your booked table - max 4 including yourself.",
        "visitor-adult": "Visitor adult:",
        "visitor-other": "Visitor student/minor:",
        "summary": "Summary",
        "check-info": "Check that the following details are correct",
        "name": "Name:",
        "day": "Day:",
        "time": "Time:",
        "table": "Table",
        "nr-of-participants": "Number of participants:",
        "total": "TOTAL:",
        "possible-discounts": "Possible student discounts are applied at checkout.<br /> A student card must be shown.",
        "hour": "hour",
        "hours": "hours",
        "booking-uanvailable": "A booking with the selected time or table has already been made. Go back and choose a new time.",
        "confirm-booking": "CONFIRM BOOKING",
        "confirmation-sent-to": "A confirmation has been sent to",
        "check-junkmail-2": "Check your spam folder if you did not receive the confirmation<br /> sent from info@theclub.fi",
        "on-table": "ON TABLE",
        "observe": "NOTICE!<br /> You must arrive at least 5 minutes before<br /> your game time starts. If you are late, your booking may be canceled.",
        "cancelation-restriction": "Bookings can only be canceled up to 15 minutes before the game time starts.",
        "cancel-booking": "Cancel booking",
        "no-active-bookings": "No active bookings",
        "all-users": "All users",
        "prices": "Prices",
        "bookable-objects": "Bookable items",
        "list-bookings": "List bookings",
        "member-fees": "Membership fees",
        "confirm-edit": "Confirm edit",
        "confirm-delete": "Are you sure you want to delete this field?",
        "write-code-here": "Enter the code here",
        "play-time": "YOUR GAME TIME",
        "dagar":"days",
        "timmar":"hours",
        "minuter":"minutes",
        "starts-in": "STARTS IN"
      }        
    }
  },
  lng: localStorage.getItem('i18nextLng') || 'fi', 
  fallbackLng: "fi", // fallback language if the current language is missing a translation
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;
