import * as XLSX from 'xlsx';
import { formatShortDate } from '../../utils';

const ExportToExcel = ({ data,selectedDate }) => {

    

  const generateExcel = () => {
    if (!data || !data.length) {
      alert("No data to export");
      return;
    }

    // Create a worksheet from the data array
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    // Write the workbook and trigger the download
    XLSX.writeFile(workbook, `member-list-${formatShortDate(selectedDate,'unformated')}.xlsx`);
  };

  return (
    <button style={{background: 'var(--main-color)', padding: '5px 10px' ,borderRadius: '6px'}} onClick={generateExcel}>
      Download list
    </button>
  );
};

export default ExportToExcel
