const AddUser = ({labels,fields,setFields,handleAddItem,getOptionLabel}) => {
   

    return (
        <div className="add-item-form card-large padding-card card">
         
            {Object.keys(labels).map((key, index) => (
                <div className="flex flex-wrap" key={index}>
                <label>{labels[key][0]}:</label>
                {/* 
                "labels[key][2]" betyder att fältet har dropdown värden
                vilket genererar en select i istället för en input
                 */}
                {labels[key] && labels[key][2]? 
                <select
                id={key}
                value={fields[key] || '' }
                onChange={(e) => {
                    setFields({ ...fields, [key]: e.target.value })
                }}
            >
                {labels[key][2].map((option) => (
                    <option key={option} value={option} >
                        {getOptionLabel(key, option)}
                    </option>
                ))}
            </select>:
                <input
                    type={labels[key][1]}
                    value={fields[key] || ''}
                    onChange={(e) => setFields({ ...fields, [key]: e.target.value })}
                />
                }
                </div>
            ))}
            <button className="button" onClick={handleAddItem}>Lägg till</button>
        </div>
    );
  };
  
  export default AddUser;