import React, { useEffect, useState } from 'react';
import { getItem, formatShortDate } from '../../utils';

const ProfileEditFields = ({ fields,labels,setFields,getOptionLabel,listType,selectedDate }) => {
  
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  
    const [editingField, setEditingField] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [fetchStatus, setFetchStatus] = useState({});

    useEffect(() => {
      setEditingField(null)
    }, [fields])
 
    const handleEditClick = (field) => {
        setEditingField(field);
        setEditValue(fields[field]);  
      };
    
      const handleSaveClick = async (field) => {
        try {
            const response = await getItem({
                action: "edit-item",     
                listType,
                // Skickar namnet på WHERE kolumnen, mail är tredje i listan och dom två andra först
                conditionKey: (fields.email ? Object.keys(labels)[3] : Object.keys(labels)[0] ),
                // Skickar värdet för WHERE kolumnen, mail är tredje i listan och dom två andra först
                conditionValue: (fields.email ? fields.email : fields[Object.keys(labels)[0]] ),
                date: formatShortDate(selectedDate,'unformated'),
                [field]: editValue
            });
          if (response.success) {
            setFields({ ...fields, [field]: editValue });
            setEditingField(null);
            setFetchStatus({ [field]: 'success' });
            setTimeout(() => setFetchStatus((prev) => ({ ...prev, [field]: null })), 2000); // Remove status after 2 seconds
          } else {
            setFetchStatus({ [field]: 'error' });
          }
        } catch (error) {
          console.error('Error saving text:', error);
          setFetchStatus({ [field]: 'error' });
        }
      };
    //console.log(fields);
    if(fields) {
    return (
        <div id='profile-form' className={`card card-large wrapper-desktop ${scroll && "fixed-top"}`}>
        {Object.entries(labels).map(([key, value]) => (
          <div key={key} className='flex flex-wrap'>
            <label htmlFor={key} >{labels[key] ? labels[key][0] : key}</label>
            <div className='flex flex-align-center-h edit-input-wrapper'>
              {labels[key] && labels[key][2]? 
              <select
                  id={key}
                  value={editingField === key ? editValue : fields[key]}                
                  onChange={(e) => {
                    setEditValue(e.target.value);
                    setEditingField(key);
                  }}
              >
                  {labels[key][2].map((option) => (
                      <option key={option} value={option} >
                          {getOptionLabel(key,option)}
                      </option>
                  ))}
              </select>:
              <input
                id={key}
                type={labels[key] ? labels[key][1] : 'text'}
                value={editingField === key ? editValue : fields[key]}
                onChange={(e) => setEditValue(e.target.value)}
                readOnly={editingField !== key}
                onClick={() => handleEditClick(key)}
              />}
              <span onClick={() => handleEditClick(key)} style={{ cursor: 'pointer', marginRight: '10px' }}>
                ✏️
              </span>
              {editingField === key && (
                <span onClick={() => handleSaveClick(key)} style={{ cursor: 'pointer', marginRight: '10px' }}>
                  💾
                </span>
              )}
              {fetchStatus[key] === 'success' && (
                <span style={{ color: 'green', marginLeft: '10px' }}>✔️</span>
              )}
              {fetchStatus[key] === 'error' && (
                <span style={{ color: 'red', marginLeft: '10px' }}>❌</span>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default ProfileEditFields;