import i18n from 'i18next';

function LanguageSwitcher({text}) {

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng); // Save to localStorage
  };
console.log(i18n.language)
  return (
    <>
      <button 
        className={`gold-link text-left ${i18n.language === 'fi' ? 'underline':''}`}
        onClick={() => changeLanguage('fi')}>
        {text === 'normal' ? 'Suomeksi':"Fi"}
      </button>
      <button 
        className={`gold-link text-left ${i18n.language === 'sv' ? 'underline':''}`}
        onClick={() => changeLanguage('sv')}>
        {text === 'normal' ? 'Svenska':"Sv"}
      </button>
      <button 
        className={`gold-link text-left ${i18n.language === 'en' ? 'underline':''}`}
        onClick={() => changeLanguage('en')}>
        {text === 'normal' ? 'English':"Eng"}
      </button>
    </>
  );
}

export default LanguageSwitcher;
