import { useState,useEffect } from "react";
import ProfileEditFields from "../components/manage-items/EditFields";


function ListAllPrices() {
    const [fields, setFields] = useState({ });
    const labels = { 
        groupname: {
            0: 'Typ av bord',
            1: 'Pool bord'
        },
    }

    return(
     <>
        <ProfileEditFields
              fields={fields}
              labels={labels}
              setFields={setFields}
            />  
     </>
    );
}

export default ListAllPrices;