import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { availableTimes } from "../../constants";
import { formatShortDate, getItem } from '../../utils';

const ActiveBookingCard = ({user,activeBookings,availableTables}) => {

  //console.log(activeBookings);

  const { t, i18n } = useTranslation();

  // Reduce to combine timeIds with matching bookingIds
  const combinedData = activeBookings.reduce((acc, current) => {
  const existingBooking = acc.find(item => item.bookingId === current.bookingId);
  
    if (existingBooking) {
      // If the bookingId already exists, add the timeId to the timeIds array
      existingBooking.timeIds.push(current.timeId);
    } else {
      // Otherwise, add a new entry with the timeId as an array
      acc.push({ ...current, timeIds: [current.timeId] });
    }

    return acc;
  }, []);

  // Remove the redundant timeId field after combining
  const finalData = combinedData.map(({ timeId, ...rest }) => rest);

    
  const formatIdName = (id,list) => {
    
   //console.log(id, typeof id === 'string')
 
    // if (!id ) {
    //   console.log(id)
    //   return '';
    // };

    


      if(typeof id !== 'string') {
        //console.log(id)
        //const [tempId1, tempId2] = id.map(idVal => list.find(item => item.id === idVal));        
        const tempId1 = list.find(item => item.id === id[0]);
        const tempId2 = list.find(item => item.id === id[1]);
        if(tempId2) {
          //
          return tempId1['type_of_object'] + ' & ' + tempId2['type_of_object'];
        }
        return tempId1['type_of_object'];
        
      }
      else {
        let tempId = list.find(item => item.id === id);
        return tempId['type_of_object'];        
      }
  }

  const calculateTimeDifference = (booking) => {            
      const [year, month, day] = booking.date.split('-');
      const [startTime] = formatIdName(booking.timeIds,availableTimes).split('–');
      const [hour, minute] = startTime.split(':');
      const futureDateTime = new Date(year, month - 1, day, hour, minute);
      const currentTime = new Date();
      return futureDateTime - currentTime;
  };

  const [timeLeft, setTimeLeft] = useState(
    finalData.map((booking) => calculateTimeDifference(booking))
  );
    
  useEffect(() => {
      const intervalId = setInterval(() => {
        setTimeLeft(
          finalData.map((booking) => calculateTimeDifference(booking))
        );
      }, 1000);

      return () => clearInterval(intervalId);
  }, [activeBookings]);

  const disableButton = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    //console.log(hours,minutes);
    if(hours === 0 && minutes <= 34) {
      return true;
    }

  }

  const removeBooking = (id,cardId) => {
      getItem({
        action: "ta-bort-bokning",
        email: user.userInfo.email,
        conditionValue:id
      });
      //console.log(finalData, 'test1',id);
      document.getElementById('card-' + cardId ).classList.add("hidden");
      finalData.filter(item => item.bookingId === id)
      //console.log(finalData.filter(item => item.bookingId !== id), 'test2');
  }

  const formatTimeLeft = (milliseconds) => {
    if (milliseconds <= 0) {
        return <div><span className="timer-clock">000</span><strong>minuter</strong></div>;
    }

    //console.log(milliseconds);

    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    if (days > 0) {
        return (
        <>
            <div>
                <span style={{marginBottom:'10px'}} className="timer-clock">{String(days).padStart(2, '0')}</span><strong>{t('dagar')}</strong> 
            </div>
            <div>
                <span style={{margin:'0 10px 10px '}} className="timer-clock">{String(hours).padStart(2, '0')}</span><strong>{t('timmar')}</strong> 
            </div>
            <div>
                <span style={{marginBottom:'10px'}} className="timer-clock">{String(minutes).padStart(2, '0')}</span><strong>{t('minuter')}</strong>
            </div>
        </>
        );
    }
    else if (hours > 0) {
      return (
      <>
          <div>
              <span style={{marginRight: '10px', marginBottom:'10px'}} className="timer-clock">{String(hours).padStart(2, '0')}</span><strong>{t('timmar')}</strong> 
          </div>
          <div>
              <span style={{marginBottom:'10px'}} className="timer-clock">{String(minutes).padStart(2, '0')}</span><strong>{t('minuter')}</strong>
          </div>
      </>
      );
  } else {
        return (
        <div>
            <span style={{marginBottom:'10px'}} className="timer-clock">{String(minutes).padStart(2, '0')}</span><strong>{t('minuter')}</strong>
        </div>
        );
    }
  };

  const currentTime = new Date().toTimeString().slice(0, 5);
  const currentDate = new Date();

  const isTimeBeforeCurrent = (time,date) => {
    
    const tempTimeId = availableTimes.find(item => item.id === time);
    //console.log(tempTimeId);

    if(new Date(date) >= currentDate) {
      return true;
    }

    // 00:00–01:00 ses som en tid på morgon och inte nästa dag
    // den är ju däremot alltid bokningsbar oberoende, väl?
    if(tempTimeId['type_of_object'] === '00:00–01:00') {
        return true;
    }
    return tempTimeId['type_of_object'] >= currentTime;
  };
  //console.log(finalData.lenght);
  return (
      <>
      <h1 style={{margin: '10px 0 20px'}}>{t('active-bookings-link')}</h1>

      {finalData.length !== 0 ?
        finalData.map((booking, index) => (isTimeBeforeCurrent(booking.timeIds[0],booking.date) &&

          <div key={index} className="card card-large wrapper-desktop text-center" id={`card-${index}`}>
            <strong>{t('confirmation-sent-to')} {user.userInfo.email}</strong>
            <p style={{ marginTop: '16px' }} className="font-smal color-gray">
              <Trans i18nKey="check-junkmail-2"></Trans>
            </p>
            <span style={{ margin: '32px auto 20px' }} className="gray-line"></span>

            <strong style={{textTransform:'uppercase'}}>{t('play-time')} {formatShortDate(booking.date,null,i18n.language)} {formatIdName(booking.timeIds,availableTimes)} {t('on-table')} {formatIdName(booking.tableId,availableTables)} {t('starts-in')}</strong>
            <div style={{ margin: '35px auto 40px' }} className='flex flex-align-center-v color-gold'>
              {formatTimeLeft(timeLeft[index])}
            </div>
            <strong>
              <Trans i18nKey='observe'></Trans>
            </strong>
            <span style={{ marginTop: '21px' }} className="gray-line"></span>
            <p>{t('cancelation-restriction')}</p>
            <button 
              style={{ marginTop: '21px', backgroundColor: 'rgb(208, 43, 43)' }} 
              className='button' 
              disabled={disableButton(timeLeft[index])} 
              onClick={() => removeBooking(booking.bookingId,index)}
              >
              {t('cancel-booking')}
              </button>
            </div>

          //:<h2>{t('no-active-bookings')}</h2>)):
          )):
        <h2>{t('no-active-bookings')}</h2>
      }
    </>
  );   
};

export default ActiveBookingCard;