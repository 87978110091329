import { useState,useEffect } from 'react';
import ProfileEditFields from '../components/manage-items/EditFields';
import MonthlyCosts from '../components/MonthlyCosts';
import Footer from "../components/Footer";
import LanguageSwitcher from "../components/LanguageSwitcher"
import sprite from '../images/icons.svg';
import { useAuth } from "../hooks/useAuth";
import { userProfileLabels } from '../constants';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Profile = () => {

  const { logout, user } = useAuth();
  const { t } = useTranslation(); // `t` function for translations  
  const [fields, setFields] = useState(user.userInfo);
  const [offsets, setOffsets] = useState([0, 0, 0,]); 

  const { page } = useParams();

  useEffect(() => { 
    setOffsets([
      page === 'edit-profile' || page === 'view-cost' ? 50 : 0,  // Second offset value for `edit-profile`
      page === 'view-cost' ? 50 : 0,    // First offset value for `view-cost`
      page === 'edit-profile' ? 50 : 0  // Second offset value for `edit-profile`      
    ]);
  }, [page]);
  
  return (
    <>
      <div id='profile' className='wrapper-desktop center card card-large overflow-hidden' style={{padding: '20px 0'}}>
       
        <div className='profile-wrapper flex'>

          <div className='profile-wrapper-card width-50 position-relative' style={{left:`-${offsets[0]}%`}}>
     
            <div className='profile-links flex flex-column'>
    
              <Link aria-label="Home" to="/profile/view-cost">
                {t('view-costs')}
              </Link>
              
              <Link aria-label="Home" to="/profile/edit-profile">
                {t('edit-profile')}
              </Link>

              <Link to='/active-bookings' >
                    {t('active-bookings-link')}
              </Link>

              <LanguageSwitcher text={'normal'} />

              <button className='gold-link text-left' onClick={logout}>Logout</button>

            </div>   
     
          </div>

          <div className='profile-wrapper-card width-50 position-absolute top height-100 overflow-scroll-y' style={{right:`${offsets[1]}%`}}>

            <Link style={{marginLeft: '20px'}} aria-label="Back" to="/profile">
              <svg width="20" height="16" >
                <use href={sprite + "#svg-arrow-back"} />
              </svg> 
            </Link>

         
              <MonthlyCosts 
                  user={user}
              />
  

          </div>

          <div className='profile-wrapper-card width-50 position-absolute top height-100 overflow-scroll-y' style={{right:`${offsets[2]}%`}}>

            <Link style={{marginLeft: '20px'}} aria-label="Back" to="/profile">
              <svg width="20" height="16" >
                <use href={sprite + "#svg-arrow-back"} />
              </svg> 
            </Link>

          
              <ProfileEditFields
                fields={fields}
                labels={userProfileLabels}
                setFields={setFields}
              /> 
     

          </div>
          
        </div>





      </div>    
      
      <Footer user={user} />
    </>
  );
};

export default Profile;
