import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import  { getItem }  from '../utils';

const MonthlyCosts = ({user}) => {
    let navigate = useNavigate(); 
    
    const [bookingFees, setBookingFees] = useState({});
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        
        const getFees = async () => {
            const response = await getItem({ 
                action: 'get-member-fees',
                user: user.userInfo.email
            });
            //console.log(response)
            setBookingFees(response)
        };  
        getFees() 

        const getBookings = async () => {
            const response = await getItem({ 
                action: 'get-user-bookings',
                user: user.userInfo.email
            });
            setBookings(response);
        };  
        getBookings() 

      }, []);
      
      return (
              
            <div style={{marginRight: '40px'}}  className="card card-large wrapper-desktop">

                

                <span>Spelavgifter denna månad: {bookingFees[0]?.total_bookings && bookingFees[0].total_bookings} €</span><br />
                <span>Medlemsavgift: {bookingFees[0]?.custom_fee ? `${bookingFees[0].custom_fee}` : `${bookingFees[0]?.member_fee || ''}`} €</span>
                <br />
                <br />
                <span>Bokningar denna månad</span><br />

                <ul id='profile-booking-list' className='list-style_none'>
                    <li className='grid'>
                        <strong>Datum</strong>
                        <strong>Inbjudna vuxna</strong>
                        <strong>Inbjudna andra</strong>
                        <strong style={{textAlign: 'right'}}>Pris</strong>
                    </li>
                    {bookings.length === 0 ? (
                        <p>Inga bokningar gjord denna månad</p>
                        ) : (    
                                bookings.map((value, index) => (
                                    <li key={index}  className='grid'>
                                        <span>{value.date}</span>
                                        <span>{value.invited_adult}</span>
                                        <span>{value.invited_other}</span>
                                        <span style={{textAlign: 'right'}}>{value.price} €</span>
                                    </li>                         
                                )
                            )
                        )
                    }
                </ul>
                {/* <span>Bokningar från andra medlemmar denna månad</span> */}
        
            </div>
            
        
    );
};

export default MonthlyCosts;