import React, { useState, useEffect } from 'react';
import { getItem, formatShortDate } from '../../utils';
import HoverInfoBox from './HoverInfoBox';

function Grid({maxItems,maxSelectable,bookedAllItems,availableTimes,availableTables,selectedDate}) {
    //console.log(availableTimes.length);
    //console.log(availableTables);

    const [elements, setElements] = useState([]);
    const [selectedItemsCount, setSelectedItemsCount] = useState(0);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [infoBoxVisible, setInfoBoxVisible] = useState(false);
    
    const [dialogVisible, setDialogVisible] = useState(false);
    const [currentEditingItem, setCurrentEditingItem] = useState(null);
    const [gridItemTexts, setGridItemTexts] = useState({}); // Stores text for each grid item
    const [inputText, setInputText] = useState("");

    useEffect(() => {
        const fetchGridNotes = async (action) => {
            const response = await getItem({
                action: "get-grid-item-note",
                date: formatShortDate(selectedDate, 'unformated'),
            });
            const initialTextMap = response.reduce((acc, note) => {
                acc[note.grid_item_id] = note.note;
                return acc;
              }, {});
            //console.log(initialTextMap)
            setGridItemTexts(initialTextMap)
        };        
        
        fetchGridNotes('get-all-prices'); 
        
      }, [selectedDate]);

    //console.log(gridItemTexts)

    useEffect(() => {
      // Function generating a list of elements
      const generatedElements = generateList(maxItems,bookedAllItems);
      setElements(generatedElements);
    }, [bookedAllItems,infoBoxVisible,gridItemTexts]);
  
    useEffect(() => {
      // Function accessing the elements after the state has been updated
      InitializeGrid(maxSelectable);
    }, [elements,maxSelectable]);


    const handleDoubleClick = (gridItemId) => {
        setCurrentEditingItem(gridItemId);
        setInputText(gridItemTexts[gridItemId] || ""); // Load existing text if available
        setDialogVisible(true);
    };

    const handleSaveText = async () => {
        setGridItemTexts((prev) => ({ ...prev, [currentEditingItem]: inputText }));

        await getItem({ 
            action: "set-grid-item-note",
            note: inputText,
            itemKey: currentEditingItem,
            date: formatShortDate(selectedDate, 'unformated'),
        });
        
        setDialogVisible(false); 
    };


    

    function generateList(maxItems,bookedAllItems) {    

        const rows = availableTimes.length - 1;
        const columns = availableTables.length - 1;
        const grid = [];

        const itemsId = availableTimes.map(item => item.id);
        const itemsName = availableTables.map(item => item.id);


        
    
        for (let row = 0; row <= rows; row++) {
            for (let col = 0; col <= columns; col++) {
                const itemNumber = (row - 1) * columns + col;
                if (itemNumber <= maxItems) {

                // Kollar om en bokning med en viss time och table id har matchande
                // värde i bookedAllItems objektet
                const isUnselectable = bookedAllItems.find(
                    item => item.timeId === String(itemsId[row]) && item.tableId === String(itemsName[col])                   
                );

                // Visar namn på den som bokat
                let bookingId = isUnselectable ? 
                    `Namn: ${isUnselectable.firstname} ${isUnselectable.lastname}<br />Tel: ${isUnselectable.tel}`
                    :''
                ;

                

                // unavailable = obokbar då tiden har gått ut
                // unselectable = obokbar då den renad är bokad/avaktiverad
                // selectable = går att boka
                let classNames = 'selectable';

                if(bookingId) {
                    classNames = classNames.concat(' booked');
                }

                const itemKey = `row-${row}-col-${col}`;
                
                if(isUnselectable) {
                    isUnselectable.note = gridItemTexts[itemKey] ? gridItemTexts[itemKey]: "";
                }
                
                //isUnselectable["key3"] = "value3";
                

                grid.push(
                    <div
                        key={itemKey}
                        data-id-time={itemsId[row]}
                        data-id-table={itemsName[col]}
                        className={`
                            list-item grid-item 
                            ${classNames} 
                            ${gridItemTexts[itemKey] ? "note":""} 
                            ${isUnselectable ? isUnselectable.type_of_member:""}
                        `}
                        onMouseEnter={() => handleMouseEnter({ row, col, time: availableTimes[row]['type_of_object'] })}
                        onMouseLeave={handleMouseLeave}
                        onDoubleClick={() => handleDoubleClick(itemKey)}
                    >
                        {availableTimes[row]['type_of_object']}
                        {infoBoxVisible && hoveredItem && hoveredItem.row === row && hoveredItem.col === col && isUnselectable && (
                            <HoverInfoBox props={isUnselectable} />
                        )}

                        <br />
                    </div>
                    );
                }
            }
        }
        return grid;
    }   

     useEffect(() => {
        let timer;
        if (hoveredItem !== null) {
            timer = setTimeout(() => {
                setInfoBoxVisible(true);
            }, 1000);
        } else {
            setInfoBoxVisible(false);
        }
        return () => clearTimeout(timer);
    }, [hoveredItem]);

    const handleMouseEnter = (item) => {
        setHoveredItem(item);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    function InitializeGrid(maxSelectable) {
    
        const grid = document.getElementById('items');
        //const grid = itemsList.current;
        //console.log(grid + "test");
        const items = grid.getElementsByClassName('selectable');
        //const items = itemsListItem;
        let isMouseDown = false;
        let startX, startY, endX, endY;
        let lastSelectedItem = null;
        let selectedCount = 0;

        const squareOverlay = document.createElement('div');
        squareOverlay.classList.add('square-overlay');
        document.body.appendChild(squareOverlay);

        function handleItemMouseDown(event) {  
            
            const selectedItem = event.target;

            //if(!selectedItem.classList.contains('unselectable')) {

            
            
                //console.log('mouse down1');
                //console.log('fyri' + startX);
                isMouseDown = true;
                startX = event.clientX;
                startY = event.clientY;
                
                
        
                if (event.ctrlKey || event.metaKey) {
                    //console.log('selectedItem class list before condition:', selectedItem.classList);
                    //console.log(selectedCount + ' ' + maxSelectable + ' ' + selectedItemsCount);

                    if (selectedItem.classList.contains('selected')) {
                        selectedItem.classList.remove('selected');
                        selectedCount--;
                        //console.log(selectedItem.classList.contains('selected'));
                        setSelectedItemsCount(prevCount => prevCount - 1);
                    } 
                    else if (selectedCount < maxSelectable) {
                        //console.log('mouse selected');
                        selectedItem.classList.add('selected');
                        selectedCount++;
                        setSelectedItemsCount(prevCount => prevCount + 1);
                        console.log('test ' + selectedCount);
                        //console.log(selectedCount + ' ' + maxSelectable);
                        
                    }                
                } 
                else if (event.shiftKey && lastSelectedItem) {
                    selectItemsInRange(selectedItem);
                } 
                else {
                    clearSelection();                
                    selectedItem.classList.add('selected');
                    lastSelectedItem = selectedItem;
                    selectedCount = 1;
                    console.log(selectedCount);
                }
        
                grid.addEventListener('mousemove', handleItemMouseMove);      
            //}    
        }



        function handleItemMouseMove(event) {            
            if (isMouseDown) {
                endX = event.clientX;
                endY = event.clientY;
    
                Array.prototype.forEach.call(items, item => {
                    const rect = item.getBoundingClientRect();
                    const itemX = rect.left;
                    const itemY = rect.top;

                    //console.log("startX is:" + endX);    
    
                    if (
                        itemX <= Math.max(startX, endX) &&
                        itemX + rect.width >= Math.min(startX, endX) &&
                        itemY <= Math.max(startY, endY) &&
                        itemY + rect.height >= Math.min(startY, endY)
                    ) {
                        if (!item.classList.contains('selected') && selectedCount < maxSelectable) {
                            //console.log('mouse selected');
                            item.classList.add('selected');
                            selectedCount++;
                        }
                    } else {
                        if (item.classList.contains('selected')) {
                            item.classList.remove('selected');
                            selectedCount--;
                        }
                    }
                });
                
                updateSquareOverlay(event);
            }
        }
    
        function handleItemMouseUp() {
            //console.log('mouse up1');
            isMouseDown = false;
            grid.removeEventListener('mousemove', handleItemMouseMove);
            clearSquareOverlay();
        }
    
        function clearSelection() {
            Array.prototype.forEach.call(items, item => {
                item.classList.remove('selected');
            });
            selectedCount = 0;
        }
    
        function selectItemsInRange(selectedItem) {
            let startIndex = Array.prototype.indexOf.call(items, lastSelectedItem);
            let endIndex = Array.prototype.indexOf.call(items, selectedItem);
            let count = 0;
    
            if (startIndex > endIndex) {
                const temp = startIndex;
                startIndex = endIndex;
                endIndex = temp;
            }
    
            for (let i = startIndex; i <= endIndex; i++) {
                if (!items[i].classList.contains('selected') && count < maxSelectable) {
                    items[i].classList.add('selected');
                    count++;
                }
            }
            selectedCount = count;
        }
        
        function updateSquareOverlay(event) {

            //startX = event.clientX;
            //startY = event.clientY;
            endX = event.clientX;
            endY = event.clientY;

            squareOverlay.style.display = 'block';
            squareOverlay.style.left = Math.min(startX, endX) + 'px';
            squareOverlay.style.top = Math.min(startY, endY) + 'px';
            squareOverlay.style.width = Math.abs(endX - startX) + 'px';
            squareOverlay.style.height = Math.abs(endY - startY) + 'px';
        }
    
        function clearSquareOverlay() {
            squareOverlay.style.display = 'none';
        }
    
        Array.prototype.forEach.call(items, item => {
            item.addEventListener('mousedown', handleItemMouseDown);
        });
    
        grid.addEventListener('mouseup', handleItemMouseUp);
        

    };


    return (
        <>
        {elements}
        {dialogVisible && (
            <div className="notes-dialog">
                <div className="notes-dialog-content">
                    <p className='bold'>Meddelande för bokningen</p><br />
                    <textarea
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        placeholder="Skriv in texten här"
                    ></textarea>
                    <button onClick={handleSaveText}>Save</button>
                    <button onClick={() => setDialogVisible(false)}>Cancel</button>
                </div>
            </div>
        )}
        </>

    );
  
}

export default Grid