import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
//import { SignJWT, jwtVerify }  from 'jose'

import LoginForm from '../components/login/LoginForm';
import LanguageSwitcher from "../components/LanguageSwitcher"

function LoginPage() {

  const { t } = useTranslation();

/*async function test2 (secretCode,jwt) {
    const secret = new TextEncoder().encode(
        secretCode,
      )

      const { payload, protectedHeader } = await jwtVerify(jwt, secret
        // , {
        //     issuer: 'urn:example:issuer',
        //     audience: 'urn:example:audience',
        //   }
      )
      
      //console.log(protectedHeader)
      //console.log(payload)

      

}


async function test() {

    const secretCode = process.env.REACT_APP_HEMLI_KODE;

    const secret = new TextEncoder().encode(
        secretCode,
      )
      const alg = 'HS256'
      
      const jwt = await new SignJWT({ 'test': 'test2' })
        .setProtectedHeader({ alg })
        //.setIssuedAt()
        //.setIssuer('urn:example:issuer')
        //.setAudience('urn:example:audience')
        .setExpirationTime('2h')
        .sign(secret)
      
      console.log(jwt)

      document.cookie = `valid-user=${jwt}; SameSite=None;`;

    //   document.cookie = `
    //   valid-user=${jwt};
    //   max-age=604800;
    //   ${process.env.NODE_ENV !== 'development' ? 'domain=booking.img-media.fi;' : ''}
    //   ${process.env.NODE_ENV !== 'development' ? 'secure;' : ''}
    //   `

      test2(secretCode,jwt);
}
test();

*/
    return (
        <div className="screen flex flex-column flex-align-top flex-align-center-h ">

            <div className="position-fixed login-lang-select">
              <LanguageSwitcher text={'short'} />  
            </div>
            

            <h1>{t('book-pool-and-darts')}</h1>
        
            <span className="screen-nav flex flex-align-center-h wrapper-width navigate-back" style={{margin: "20px auto 59px"}}>
                {/* <a className="screen-nav-back" href="/" title="Back">
                    <svg width="20" height="16" >
                            <use href={sprite + "#svg-arrow-back"} />
                    </svg>                     
                </a> */}
                <h2>{t('login')}</h2>
            </span>

            <div id="screen-body-login" className="card card-large flex flex-column flex-align-top flex-align-center-h wrapper-desktop" style={{paddingTop: "38px;padding-bottom:46px"}}>

               <LoginForm />

               <Link aria-label="Password reset" to="/password-reset">{t('forgotten-password')}</Link>

                <p style={{margin: "25px auto 26px"}}>{t('has-no-account')} <Link to="/register"> {t('register')}</Link></p>

                <p className="font-smal" style={{textAlign: "center"}}> {t('use-and-accept')}<br />
                    <Link aria-label="Terms" className="font-smal" to="/terms"> {t('terms')}</Link>
                    &nbsp; {t('and')}&nbsp;
                    <Link aria-label="Privacy policy" className="font-smal" to="/gdpr"> {t('privacy-policy')}</Link>
                </p>

            </div> 
            
        </div>
    );
  }

export default LoginPage;