import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { getItem } from "../utils";

export const AdminRoute = ({ children }) => {
  const { user } = useAuth();
  const [hasAccess, setHasAccess] = useState(null); // Tracks the user's access
  const [loading, setLoading] = useState(true); // Tracks loading state

  const CONFIRM_ADMIN = 'sF98s0Fdf8s0fsS';

  useEffect(() => {
    const checkAccess = async () => {
      if (!user) {
        setLoading(false); // If user is not authenticated, stop loading
        return;
      }

      try {
        const response = await getItem({
          action: "check-user-credentials",
          user: user.userInfo.email,
        });

        // Check if the user has the correct access
        const isAdmin =
          //response.hasAccess === process.env.CONFIRM_ADMIN &&
          response.hasAccess === CONFIRM_ADMIN &&
          user?.userInfo.userAccess === "125633534";

        console.log(CONFIRM_ADMIN);

        setHasAccess(isAdmin); // Set access status
      } catch (error) {
        console.error("Error checking access:", error);
        setHasAccess(false); // Default to no access on error
      } finally {
        setLoading(false); // Loading is done
      }
    };

    checkAccess();
  }, [user]);

  // Handle loading state
  if (loading) {
    return <div className="spinner"></div>;
  }

  // Redirect unauthenticated users to the login page
  if (!user) {
    return <Navigate to="/login" />;
  }

  // Render children if the user is authorised
  if (hasAccess) {
    return children;
  }

  // Render "Not authorised" message if the user is not authorised
  return <p>Not authorised</p>;
};
